<template>
  <ChangePasswordWrapper>
    <sdCards>
      <template v-slot:title>
        <div class="setting-card-title">
          <sdHeading as="h4">{{ i18n.t('profileView.changePassword') }}</sdHeading>
          <span>{{ i18n.t('profileView.passwordSubtitle') }}</span>
        </div>
      </template>
      <BasicFormWrapper>
        <a-form :model="formState" @finish="handleFinish" :rules="rules">
          <a-form-item ref="current_password" name="current_password" :label="i18n.t('editPasswordView.oldPassword')">
            <a-input-password v-model:value="formState.current_password" />
          </a-form-item>
          <a-form-item ref="password" name="password" :label="i18n.t('editPasswordView.newPassword')">
            <a-input-password v-model:value="formState.password" />
          </a-form-item>
          <a-form-item
            ref="password_confirmation"
            name="password_confirmation"
            :label="i18n.t('editPasswordView.confirmPassword')"
          >
            <a-input-password v-model:value="formState.password_confirmation" />
          </a-form-item>
          <a-form-item>
            <sdButton htmlType="submit" :disabled="isLoading" outlined type="success">
              {{ i18n.t('editPasswordView.changePassword') }}
            </sdButton>
          </a-form-item>
        </a-form>
      </BasicFormWrapper>
    </sdCards>
  </ChangePasswordWrapper>
</template>

<script>
import { ChangePasswordWrapper } from './style';
import { BasicFormWrapper } from '../../../styled';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
const Password = defineComponent({
  name: 'Password',
  components: { BasicFormWrapper, ChangePasswordWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const formState = reactive({});
    const i18n = useI18n();
    const isLoading = computed(() => state.profile.isLoading);
    const alertType = ref('');
    const alertText = ref('');
    const error = computed(() => state.profile.error);
    const handleFinish = () => {
      if (formState.password !== formState.current_password) {
        dispatch('axiosChangePassword', formState).then(() => {
          if (!error.value) {
            message.success(i18n.t('messages.passwordChanged'));
          } else {
            message.error(error.value.password[0]);
          }
        });
      } else if (formState.password === formState.current_password) {
        message.error(i18n.t('messages.passwordsAreSame'));
      }
    };

    const passwordValidator = ({ field }) => {
      if (!rules[field][0].trigger[1]) {
        rules[field][0].trigger[1] = 'change';
      }
      if (formState[field] == '') {
        return Promise.reject(i18n.t('authPage.errorPassword'));
      } else if (formState[field].length < 6) {
        return Promise.reject(i18n.t('authPage.errorPasswordMin'));
      }
      return Promise.resolve();
    };

    const rules = reactive({
      password: [
        {
          trigger: ['blur'],
          validator: passwordValidator,
        },
      ],
      password_confirmation: [
        {
          trigger: ['blur'],
          validator: passwordValidator,
        },
      ],
    });

    return {
      formState,
      rules,
      alertText,
      alertType,
      handleFinish,
      i18n,
      isLoading,
    };
  },
});

export default Password;
</script>
